import { useRef, useState, useEffect } from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import {faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%_]).{8,24}$/;
const EMAIL_REGEX = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const Register = () => {
    const emailRef = useRef();
    const errRef = useRef();
    
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [username, setUsername] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUsernameFocus] = useState(false);
    
    const [password, setPassword] = useState('');
    const [validPassword, setvalidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [matchPassword, setmatchPassword] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    //const [validationMessage, setValidationMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);

    const emailValidationMessage = "Enter a valid email address."
    const userValidationMessage = "Must begin with a letter. Letters, numbers, underscores, hyphens allowed. 4 to 24 characters."
    const passwordValidationMessage = "8 to 24 characters. Must include uppercase and lowercase letters, a number and a special character. Allowed special characters: ! @ # $ % _"
    const matchValidationMessage = "Must match the first password input field."

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    // Checks focus and changes message to help with current field
    useEffect(() => {
        (!validEmail || !validName || !validPassword || !validMatch) ? setSubmitStatus(true) : setSubmitStatus(false);
    })

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])


    useEffect(() => {
        setValidName(USER_REGEX.test(username));
    }, [username])

    useEffect(() => {
        setvalidPassword(PWD_REGEX.test(password));
        setValidMatch(password === matchPassword);
    }, [password, matchPassword])

    const registerHandler = async (e) => {
        e.preventDefault();

        const v1 = EMAIL_REGEX.test(email);
        const v2 = USER_REGEX.test(username);
        const v3 = PWD_REGEX.test(password);
        if (!v1 || !v2 || !v3) {
            setErrMsg("Invalid Entry");
            return;
        }

        setSubmitLoading(true);
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };

        try {
            const { data } = await axios.post("/api/auth/register", {username, email, password}, config);
            setSuccess(true);
        } catch (error) {
            if(error.response.data.error){
                setErrMsg(error.response.data.error);
            }else{
                setErrMsg(`${error.response.status}: ${error.response.statusText}`);
            };
            console.log(error.response);
            errRef.current.focus();
            setTimeout(() => {
                setErrMsg("")
            }, 5000 );
        } finally {
            setSubmitLoading(false);
        };
    };

    return (
        <div className="Register">
            <section id="registration_block">
                {success ? (
                    <section>
                        <h1>Success!</h1>
                        <p className="other-options">
                            <Link to="/login">Back to Login</Link>
                        </p>
                    </section>
                 ) : ( <>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}>{errMsg}</p>
                    <h1>Register</h1>

                    <form className="registrationForm" onSubmit={registerHandler}>
                        <div className="regField">
                            <div>
                                <label className="regLabel" htmlFor="email">
                                    Email:
                                    <FontAwesomeIcon icon={faCheck} className={validEmail ? "valid" : "hide"} />
                                    <FontAwesomeIcon icon={faTimes} className={validEmail || !email ? "hide" : "invalid"}/>
                                    <span className={validEmail || !email ? "hide" : "tooltiptext"}>{emailValidationMessage}</span>
                                </label>
                            </div>
                            <input
                                type="text"
                                id="email"
                                ref={emailRef}
                                autoComplete="off"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                required
                                onFocus={() => {setEmailFocus(true); setErrMsg("")}}
                                onBlur={() => setEmailFocus(false)}
                            />
                        </div>
                        <div className="regField">
                            <div>
                                <label className="regLabel" htmlFor="username">
                                    Username:
                                    <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                                    <FontAwesomeIcon icon={faTimes} className={validName || !username ? "hide" : "invalid"} />
                                    <span className={validName || !username ? "hide" : "tooltiptext"}>{userValidationMessage}</span>
                                </label>
                            </div>
                            <input
                                type="text"
                                id="username"
                                autoComplete="off"
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                                required
                                onFocus={() => {setUsernameFocus(true); setErrMsg("")}}
                                onBlur={() => setUsernameFocus(false)}
                            />
                        </div>
                        <div className="regField">
                            <div>
                                <label className="regLabel" htmlFor="password">
                                    Password:
                                    <FontAwesomeIcon icon={faCheck} className={validPassword ? "valid" : "hide"} />
                                    <FontAwesomeIcon icon={faTimes} className={validPassword || !password ? "hide" : "invalid"} />
                                    <span className={validPassword || !password ? "hide" : "tooltiptext"}>{passwordValidationMessage}</span>
                                </label>
                            </div>
                            <input
                                type="password"
                                id="password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                required
                                onFocus={() => {setPasswordFocus(true); setErrMsg("")}}
                                onBlur={() => setPasswordFocus(false)}
                            />
                        </div>
                        <div className="regField">
                            <div>
                                <label className="regLabel" htmlFor="confirm_password">
                                    Confirm Password:
                                    <FontAwesomeIcon icon={faCheck} className={validMatch && matchPassword ? "valid" : "hide"} />
                                    <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPassword ? "hide" : "invalid"} />
                                    <span className={validMatch || !matchPassword ? "hide" : "tooltiptext"}>{matchValidationMessage}</span>
                                </label>
                            </div>
                            <input
                                type="password"
                                id="confirm_password"
                                onChange={(e) => setmatchPassword(e.target.value)}
                                value={matchPassword}
                                required
                                onFocus={() => {setMatchFocus(true); setErrMsg("")}}
                                onBlur={() => setMatchFocus(false)}
                            />
                        </div>
                        <>
                            {submitLoading
                                ? <button disabled={true} ><div className="loader"/></button>
                                : <button disabled={submitStatus}>Sign Up</button>
                            }
                        </>
                    </form>

                    <p className="other-options">
                        <p>Already registered?</p>
                        <Link to="/login">Login</Link>
                    </p>


                    </>)}
                </section>
        </div>
    )
}

export default Register;
