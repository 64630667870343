import React from 'react'

export const Welcome = () => {
  return (
    <div className='Welcome'>
        <div>Horror-Movie-A Day-For-The-Month-of-October</div>
        <div>GhoulsTober</div>
        <div>GhoulsTober III: Origins</div>
        <div>GhoulsTober IV: Skele-tober</div>
        <div>GhoulsTober V: Evolution</div>
        <div>GhoulsTober VI: Apocalypse</div>
        <div>GhoulsTober VII: Last Stand of Mecha-Ghoul</div>
        <div>GhoulsTober VIII: Camp Kursedbythagool</div>
        <div>GhoulsTober IX: The Haunting of Tober House</div>
        <div>GhoulsTober X: The Ghoulstober Man</div>
        <div>GhoulsTober XI: Taste the Blood of Ghoul</div>
        <h1 className='title'>GhoulsTober XII</h1>
        <p>Coming 2023</p>
    </div>
  )
}

export default Welcome;

