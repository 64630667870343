import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import useRefreshToken from "../../hooks/useRefreshToken";

const Home = () => {
    const { setAuth } = useAuth();
    const refresh = useRefreshToken();

    const logoutHandler = async (e) => {
        e.preventDefault();
    
        const config = {
            headers: { "Content-Type": "application/json" },
            withCredentials: true
        };
    
        try {
            await axios.post("/api/auth/logout", { }, config);
            setAuth(null);
    
        } catch (error) {
            console.log(error);
        }
    }

    return (

        <div className="Home">
            <div className="header">Header</div>
            <div className="sidebar">Sidebar</div>
            <div className="content">Content</div>
            <div className="sidebar">Sidebar 2</div>
            <div className="footer">Footer</div>

            <button onClick={logoutHandler}>Log Out</button>
            <button onClick={() => refresh()}>Refresh</button>
            <Link to="/admin">Admin</Link>
        </div>

        

    )
}

export default Home;
