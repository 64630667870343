import { useLocation, Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Missing = () => {
    const { auth } = useAuth();
    const location = useLocation();  

    return (

        <>
            {auth?.id
                ? <Navigate to="/login" />
                : 
                <div className="Login">
                    <h1>Missing</h1>
                </div>
            }
        </>

    )
}

export default Missing;
