//import User from "./User";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";

const AdminDashboard = () => {

    const [users, setUsers] = useState();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const effectRun = useRef(false);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();


        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/api/users', {
                    signal: controller.signal
                });
                isMounted && setUsers(response.data.users);
            } catch (err) {
                console.error(err);
                navigate('/home', { state: { from: location }, replace: true });
            }
        };

        if (effectRun.current) {
            getUsers();
        }

        return () => {
            isMounted = false;
            controller.abort();
            effectRun.current = true;
        }
    }, [])


    return (
        <div className="AdminDashboard">
            <h1>Admin Dashboard</h1>
            <div className="user_list"></div>
            {users?.length
                ? (
                    <div>
                        {users.map((user, i) => 
                            <div key={i} className="user">
                                <div>{user?.email}</div>
                                <div>{user?.username}</div>
                                <div>{user?.role}</div>
                                <div>
                                    <button>Edit</button>
                                    <button>Send Password Reset</button>
                                    <button>Delete</button>
                                </div>
                            </div>
                        )}
                    </div>
                ) : <p>No users to display</p>
            }

        <Link to="/home">Home</Link>




            {/*
            <div className="AdminDashboard">
                <h1>Admin Dashboard</h1>
                <div className="user_list">

                        <div className="headings">
                            <div className="email_header">Email</div>
                            <div className="username_header">Username</div>
                            <div className="role_header">Role</div>
                            <div className="password_reset">Actions</div>                            
                        </div>

                        <User className="row1"/>
                        <User className="row2"/>             
                    
                </div>
            </div>
            */}

        </div>


    )
}

export default AdminDashboard;
