

const Unauthorized = () => {

    return (

        <div className="Login">
            <h1>Unauthorized</h1>
        </div>

    )
}

export default Unauthorized;
