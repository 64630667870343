import { useLocation, Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Missing = () => {
    const { auth } = useAuth();
    const location = useLocation();  

    return (

        <>
            {auth?.id
                ? <Navigate to="/login" />
                : <Navigate to="/welcome" />
            }
        </>

    )
}

export default Missing;
