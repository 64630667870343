import { Routes, Route } from "react-router-dom";

//Screens
import ForgotPasswordScreen from "./components/screens/ForgotPasswordScreen.js";
import ResetPasswordScreen from "./components/screens/ResetPasswordScreen.js";
import Register from "./components/screens/Register.js";
import Login from "./components/screens/Login.js";
import RequireAuth from "./components/RequireAuth.js";
import PersistLogin from "./components/PersistLogin.js";
import Layout from "./components/Layout";
import Home from "./components/screens/Home.js";
import Missing from "./components/screens/Missing.js";
import BaseRedirect from "./components/screens/BaseRedirect.js";
import AdminDashboard from "./components/screens/AdminDashboard.js";
import Unauthorized from "./components/screens/Unauthorized.js";
import Welcome from "./components/screens/Welcome.js";
import ForgotPassword from "./components/screens/ForgotPassword.js"
import Profile from "./components/screens/Profile.js"

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          {/*   Public Routes   */}
          <Route exact path="/login" element={<Login />}/>
          <Route exact path="/register" element={<Register />}/>
          <Route exact path="/forgotpassword" element={<ForgotPassword />}/>
          <Route exact path="/passwordreset/:resetToken" element={<ResetPasswordScreen />}/>
          <Route exact path="/unauthorized" element={<Unauthorized />}/>

          {/*   Private Routes   */}
          <Route element={<PersistLogin />}>
            {/*   Admin Routes   */}
            <Route element={<RequireAuth allowedRoles={["Admin"]} />}>
              <Route exact path="/admin" element={<AdminDashboard />}/>
            </Route>

            {/*   User Routes   */}
            <Route element={<RequireAuth allowedRoles={["User", "Admin"]} />}>
              <Route exact path="/welcome" element={<Welcome />}/>
              <Route exact path="/home" element={<Home />}/>
              <Route exact path="/profile" element={<Profile />}/>
            </Route>
          </Route>


          {/*   Catch All   */}
          <Route path="/" element={<BaseRedirect />} />
          <Route path="*" element={<Missing />} />

        </Route>
      </Routes>
    </div>
  );
};

export default App;
