import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../../api/axios"
import {faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EMAIL_REGEX = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const ForgotPasswordScreen = () => {
    const emailRef = useRef();
    const errRef = useRef();
    
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    //const [validationMessage, setValidationMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const emailValidationMessage = "Enter a valid email address."


    useEffect(() => {
        emailRef?.current?.focus();
    }, [])

    useEffect(() => {
        (!validEmail ? setSubmitStatus(true) : setSubmitStatus(false));
    })    

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    const forgotPasswordHandler = async (e) => {
        e.preventDefault();
        setSubmitLoading(true);

        const config = {
            header: {
                "Content-Type": "application/json"
            }
        };

        try {
            const { data } = await axios.post("/api/auth/forgotpassword", { email }, config);
            setEmailSent(true);
            
        } catch (error) {
            if (error?.response?.data?.error) {
                setErrMsg(error?.response?.data?.error);
            } else {
                setErrMsg("Unknown Error");
            }
            setEmail("");
            setTimeout(() => {
                setErrMsg("")
            }, 5000 );

        } finally {
            setSubmitLoading(false);
        }
    }

    return(

        <div className="ForgotPassword">
            <section id="forgotPassword_block">
                {emailSent
                    ?   <>
                            <h1>Email sent!</h1>
                            <p>If there's an account registered to this email address, you'll receieve an email shortly</p>
                            <p className="other-options">
                                <Link to="/login">Back to Login</Link>
                            </p>
                        </>
                        
                    :   <>
                            <h1>Fogot Password</h1>
                            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}>{errMsg}</p>

                            <form className="forgotPasswordForm" onSubmit={forgotPasswordHandler}>
                                <div className="fpField">
                                    <div>
                                        <label className="fpLabel" htmlFor="email">
                                            Email:
                                            <FontAwesomeIcon icon={faCheck} className={validEmail ? "valid" : "hide"} />
                                            <FontAwesomeIcon icon={faTimes} className={validEmail || !email ? "hide" : "invalid"}/>
                                            <span className={validEmail || !email ? "hide" : "tooltiptext"}>{emailValidationMessage}</span>
                                        </label>
                                    </div>
                                    <input
                                        type="text"
                                        id="email"
                                        ref={emailRef}
                                        autoComplete="off"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        required
                                        onFocus={() => {setEmailFocus(true); setErrMsg("")}}
                                        onBlur={() => setEmailFocus(false)}
                                    />
                                </div>
                                {submitLoading
                                    ? <button disabled={true} ><div className="loader"/></button>
                                    : <button disabled={submitStatus}>Send Email</button>
                                }
                                

                            </form>
                            <p className="other-options">
                                <Link to="/login">Back to Login</Link>
                            </p>

                        </>}
            </section>
        
        
        
        
        
            {/*<div className="forgotpassword-screen">
                <form className="forgotpassword-screen__form" onSubmit={forgotPasswordHandler}>
                    <h3 className="forgotpassword-screen__title">ForgotPassword</h3>

                    {error && <span className="error-message">{error}</span>}
                    {success && <span className="success-message">{success}</span>}

                    <div className="form-group">
                        <p className="forgotpassword-screen__subtext">
                            Please enter the email address you registered your account with.
                            We will send you a reset password confirmation to this email.
                        </p>

                        <label htmlFor="email">Email:</label>
                        <input 
                            type="email" 
                            required
                            id="email" 
                            placeholder="Enter email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <button type="submit" className="btn btn-primary">Send Email</button>
            </form>*/}
        </div>
    );
};

export default ForgotPasswordScreen;