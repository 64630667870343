import { useRef, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
//import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import TextInput from "../TextInput";

export const Login = () => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const destination = location.state?.from?.pathname || "/welcome";

    const emailRef = useRef();
    const errRef = useRef();
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [loginLoading, setLoginLoading] = useState(false);

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email, password])

    const loginHandler = async (e) => {
        e.preventDefault();
        setLoginLoading(true);
        const config = {
            headers: { "Content-Type": "application/json" },
            withCredentials: true
        };

        try {
            const response = await axios.post("/api/auth/login", { email, password }, config);
            const id = response?.data?.id;
            const role = response?.data?.role;
            const authToken = response?.data?.authToken;
            setAuth({ id, role, authToken });
            navigate(destination, {replace: true });
        
        } catch (error) {
            setErrMsg(error.response.data.error);
            setTimeout(() => {
                setErrMsg("")
            }, 5000 );
        } finally {
            setLoginLoading(false);
        }

    }

    return (
        <div className="Login">
            <div className="title">GhoulsTober</div>
            <div className="login_fields">
                <form onSubmit={loginHandler}>
                    <TextInput 
                        id="email"
                        label="Email"
                        type="text"
                        refProp={emailRef}
                        autoComplete="off"
                        required={true}
                        fieldValue={email}
                        changeAction={setEmail}
                    />
                    <TextInput 
                        id="password"
                        label="Password"
                        type="password"
                        required={true} 
                        fieldValue={password}
                        changeAction={setPassword}
                    />
                    {loginLoading
                        ? <button disabled={true} ><div className="loader"/></button>
                        : <button>Log in</button>
                    }

                </form >
                <p ref={errRef} className={errMsg ? "errmsg" : "hide"}>{errMsg}</p>
                <div className="login_other-options">
                    <p>Forgot password?</p>
                    <Link to="/forgotpassword">Reset Password</Link>
                    <p>Not registered?</p>
                    <Link to="/register">Sign Up</Link>
                </div>
            </div>
        </div>
    )
}

export default Login;
