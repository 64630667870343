

function TextInput({ id, label, type, refProp, autoComplete, required, fieldValue, changeAction }) {

  return (
    <div className="text-input">
      <input 
        id={id}
        type={type}
        ref={refProp}
        autoComplete={autoComplete}
        required={required}
        onChange={(e) => changeAction(e.target.value)}
        value={fieldValue}
      />
      <label htmlFor={id} className={fieldValue ? 'filled' : ''}>
        {label}
      </label>
    </div>
  );

}

export default TextInput;