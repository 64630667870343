import { useState, useEffect, useRef } from "react";
import axios from "../../api/axios"
import { Link, useParams, Navigate } from "react-router-dom";
import {faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%_]).{8,24}$/;

const ResetPasswordScreen = ({ match }) => {
    const errRef = useRef();

    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [errMsg, setErrMsg] = useState('');

    const [pageLoading, setPageLoading] = useState();
    const [tokenValid, setTokenValid] = useState(true);
    const { resetToken } = useParams();

    const [password, setPassword] = useState('');
    const [validPassword, setvalidPassword] = useState(false);

    const [matchPassword, setMatchPassword] = useState('');
    const [validMatch, setValidMatch] = useState(false);

    const [submitStatus, setSubmitStatus] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [passwordReset, setPasswordReset] = useState(false);

    const passwordValidationMessage = "8 to 24 characters. Must include uppercase and lowercase letters, a number and a special character. Allowed special characters: ! @ # $ % _"
    const matchValidationMessage = "Must match the first password input field."


    //Check field validity on any change
    useEffect(() => {
        (!validPassword || !validMatch) ? setSubmitStatus(true) : setSubmitStatus(false);
    })

    //Check Reset Password token validity on page load
    useEffect(() => {
        setPageLoading(true);

        const idCheck = async () => {
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            
            try {
                const { status } = await axios.get(`/api/auth/checkresettoken/${resetToken}`, config);
                setTokenValid(true);
            } catch(error) {
                setTokenValid(false);
            } finally {
                setPageLoading(false);
            }
        }

        idCheck();
    }, [])

    //Checks password and match validity on either field change
    useEffect(() => {
        setvalidPassword(PWD_REGEX.test(password));
        setValidMatch(password === matchPassword && matchPassword !== "");
    }, [password, matchPassword])


    //Handles password reset submit
    const resetPasswordHandler = async (e) => {
        e.preventDefault();
        setSubmitLoading(true);

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };

        if(password !== matchPassword) {
            setPassword("")
            setConfirmPassword("")
            setTimeout(() => {
                setError("")
            }, 5000 );
            return setError("Passwords do not match");

        };

        try {
            const { data } = await axios.put(`/api/auth/resetpassword/${resetToken}`, { password }, config);
            setPasswordReset(true);
            
        } catch (error) {
            setErrMsg(error.response.data.error);
            setTimeout(() => {
                setErrMsg("")
            }, 5000 );
        } finally {
            setSubmitLoading(false);
        }
    }

    return(
        <>
            {pageLoading
                ? <span>Loading...</span>
                : !tokenValid
                    ? <Navigate to="/missing" />
                    : <div className="ResetPassword">
                        <section id="resetPassword_block">
                            {passwordReset
                                ?   <>
                                        <h1>Password reset successful</h1>
                                        <p className="other-options">
                                            <Link to="/login">Back to Login</Link>
                                        </p>
                                    </>

                                :   <>
                                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}>{errMsg}</p>
                                        <h1>Reset Password</h1>

                                        <form className="resetPasswordForm" onSubmit={resetPasswordHandler}>
                                            <div className="rpField">
                                                <div>
                                                    <label className="rpLabel" htmlFor="email">
                                                        Password:
                                                        <FontAwesomeIcon icon={faCheck} className={validPassword ? "valid" : "hide"} />
                                                        <FontAwesomeIcon icon={faTimes} className={validPassword || !password ? "hide" : "invalid"}/>
                                                        <span className={validPassword || !password ? "hide" : "tooltiptext"}>{passwordValidationMessage}</span>
                                                    </label>
                                                </div>
                                                <input
                                                    type="password"
                                                    autoComplete="off"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    value={password}
                                                    required
                                                />
                                            </div>

                                            <div className="rpField">
                                                <div>
                                                    <label className="rpLabel" htmlFor="email">
                                                        Confirm Password:
                                                        <FontAwesomeIcon icon={faCheck} className={validMatch ? "valid" : "hide"} />
                                                        <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPassword ? "hide" : "invalid"}/>
                                                        <span className={validMatch || !matchPassword ? "hide" : "tooltiptext"}>{matchValidationMessage}</span>
                                                    </label>
                                                </div>
                                                <input
                                                    type="password"
                                                    autoComplete="off"
                                                    onChange={(e) => setMatchPassword(e.target.value)}
                                                    value={matchPassword}
                                                    required
                                                />
                                            </div>
                                            {submitLoading
                                                ? <button disabled={true} ><div className="loader"/></button>
                                                : <button disabled={submitStatus}>Submit</button>
                                            }
                                        </form>
                                    </>}
                        </section>
                    </div>
            }
        </>
    );
};

export default ResetPasswordScreen;